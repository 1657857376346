
import classNames from "classnames";

const Footer = () => {

    const pathname = window.location.pathname;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const pages = [{ name: 'Privacy policy', href: '/privacy' }, { name: 'Legal notices', href: '/legalnotices' }, { name: 'Contact us', href: '/contact' }]
    const listPages = pages.map(({ name, href }) =>
        <li
            className="hidden lg:block"
            key={href}>
            <a
                className={classNames('text-xs uppercase opacity-70 transition-opacity hover:!opacity-100', pathname == href && '!opacity-100')}
                href={href}>
                {name}
            </a>
        </li>
    )

    return (
        <div className="mt-auto w-full pt-4 pb-12 bg-gradient-to-t from-slate-50 to-slate-200 flex flex-col items-center">
            <div className="page flex flex-row items-center">

                <a href="/">
                    <img
                        className="h-4"
                        src={require("../assets/svg/logo.svg").default} />
                </a>


                <ul className="ml-auto flex flex-row items-center space-x-2">
                    <li><span className="pr-4 font-light text-xs uppercase opacity-70">© {currentYear} Tango Delta</span></li>
                    {listPages}
                </ul>


            </div>
        </div>
    )

}

export default Footer;