
import axios from 'axios';

export const getData = async () => {

    // console.log('FETCHING FROM SERVER!')

    //region: us-central1
    // const URL = 'http://127.0.0.1:5001/demobuilder-d24ed/us-central1/getData';
    const URL = 'https://us-central1-demobuilder-d24ed.cloudfunctions.net/getData';

    try {

        const result = await axios.post(URL);
        return result.data;

    } catch (err) {
        console.log('err: ', err);

    }

    return null;

}

export const getLocation = async () => {
 
    const URL = 'https://ipapi.co/json/?key=7Y9fgnUeYOxnfrdVq9uJlf5WfosW0MceqGzxxZ1X6ra75wQpnN';

    try {

        const result = await axios.get(URL);
        return result.data;

    } catch (err) {
        console.log('err: ', err);

    }

    return null;

}
