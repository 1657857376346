import { useState } from "react"

import classNames from "classnames"

const faqPlans = [

    {
        q: 'What is the difference between the STANDBY and CREATOR plans?',
        a: 'When you begin creating videos, you\'ll need to convert text to audio. Once the text is converted to audio, you won\'t need as much credit per month for text-to-audio because it\'s already been converted. So, after you create your videos, in the next month, you can safely downgrade to STANDBY and save money.'
    },
    {
        q: 'At which point in time can I cancel my subscription?',
        a: 'You can cancel your subscription at any time. At the next billing cycle we will not resume your subscription.'
    },
    {
        q: 'What is the billing interval?',
        a: 'We bill you on a monthly basis starting from the day you subscribed, you can cancel your subscription at any time.'
    },

    {
        q: 'Is the content I create permissible for commercial use?',
        a: 'Yes, the content you generate can be used for commercial purposes, but only if you are subscribed to a plan.'
    },


    {
        q: 'How are characters counted for text-to-audio?',
        a: 'You are limited by the number of characters you convert to audio. It doesn\'t matter which language or voice you select. This counting happens only once; in other words, if the text has been converted to audio once, you are not counted again whenever you use that text.'
    },

    {
        q: 'What happens to my unused characters at the end of the month?',
        a: 'Unused characters do not roll over into the next month. Your subscription will automatically renew with each billing cycle and your characters will reset. However, if you upgrade to a higher plan, any unused characters from your previous plan will roll over into the next billing cycle.'
    },



]


const Faq = () => {


    const [expanded, setExpanded] = useState([0]);

    const toggle = (i) => expanded.includes(i) ? setExpanded(prev => prev.filter(index => index != i)) : setExpanded(prev => [...prev, i]);

    const plus = require("../assets/svg/plus.svg").default;
    const minus = require("../assets/svg/minus.svg").default;


    const listQA = faqPlans.map(({ q, a }, i) =>
        <li
            className="pl-4 pr-2 py-2 bg-white border border-gray-100 rounded flex flex-row items-start space-x-2"
            key={q}>


            <div
                className="cursor-pointer flex-1"
                onClick={() => toggle(i)}>


                <p className={classNames('opacity-90 text-gray-700 transition-opacity duration-150 hover:!opacity-100', expanded.includes(i) && '!opacity-100 !text-blue-400')}>{q}</p>


                {expanded.includes(i) &&
                    <p className="my-2 opacity-70">{a}</p>
                }

            </div>


            <button
                className="p-1"
                onClick={() => toggle(i)}>

                <img
                    className="h-4 opacity-80"
                    src={expanded.includes(i) ? minus : plus} />

            </button>




        </li>
    )

    return (
        <>
            <h1 className="font-semibold">Faq</h1>
            <ul className="mt-8 flex flex-col space-y-4">
                {listQA}
            </ul>

        </>
    )

}

export default Faq;