
import Film from "../common/Film";

const Privacy = () => {

    document.title = 'Demo Builder - Privacy';
    document.getElementsByTagName('meta')['description'].content = 'Your privacy is important to us.';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, privacy';



    return (
        <>
            <Film title="Privacy Policy" description="Your privacy is important to us." />

            <div className="page flex flex-col space-y-4">

                <h1 className="font-semibold text-lg">Tango Delta created this statement to demonstrate our firm commitment to personal privacy.</h1>

                <p>The following discloses our information gathering and dissemination practices for demo-builder.com site. The registration forms that we use require users to give us contact information (name and e-mail address). We use this to send notifications to the user related to Tango Delta products only when it is requested by the user. Customer's contact information is also used to contact them when necessary. This contact information is never given, rented or sold to anyone and is used solely by Tango Delta.</p>

                <p>We also use very broad demographic data about our visitors, none of which can be associated with any one individual. This site contains links to other sites. Tango Delta is not responsible for the privacy practices or the content of these other web sites.</p>

                <p>Credit card payments are processed by stripe.com on a secure server. The credit card number is encrypted before it passes over the Internet. Your credit card number is neither decrypted nor recorded by Tango Delta or our computers. Buying from Tango Delta is far more secure than a credit card purchase at your local store.</p>

                <p>If you have any questions about this privacy statement, please contact us.</p>

            </div>

        </>
    )

}


export default Privacy;