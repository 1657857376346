import './App.css';

import { useState } from 'react';
import { useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Menu from './common/Menu';
import Footer from './common/Footer';

import Index from './pages/Index';
import Features from './pages/Features';
import Samples from './pages/Samples';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';

import Tutorials from './pages/Tutorials';

import Privacy from './pages/Privacy';
import LegalNotices from './pages/LegalNotices';

import CookieConsent from './common/CookieConsent';

import { getData } from './rest';


// const CACHE_VALID = -1;
const CACHE_VALID = 12*60;//minutes



const useData = () => {

  let cache = null;

  const local = localStorage.getItem('data');
  const fetched = localStorage.getItem('fetched');

  if (local && fetched) {

    const d1 = new Date()
    const d2 = new Date(fetched);

    const ms = d1 - d2;
    const minutes = Math.floor(ms / 1000 / 60);

    if (minutes < CACHE_VALID) cache = JSON.parse(local);

  }

  const [data, setData] = useState(cache || { samples: [], tutorials: [], plans: [] });

  useEffect(() => {
    if (cache) return;

    const load = async () => {

      const result = await getData();
      if (!result) return;

      setData(result);

      localStorage.setItem('data', JSON.stringify(result));

      const now = new Date();
      localStorage.setItem('fetched', now);

    }

    load();


  }, [])


  return data;

}


function App() {

  const data = useData();
  const { samples, tutorials, plans } = data;



  return (
    <div className="min-h-screen flex flex-col items-center">

      <Menu />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/features" element={<Features />} />
          <Route path="/samples" element={<Samples samples={samples} />} />
          <Route path="/pricing" element={<Pricing plans={plans} />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/tutorials" element={<Tutorials tutorials={tutorials} />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/legalnotices" element={<LegalNotices />} />

          <Route path="*" element={<Index />} />
        </Routes>
      </BrowserRouter>

      <CookieConsent />

      <Footer />

    </div>

  );
}

export default App;
