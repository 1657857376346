import { useState } from "react";
import { useEffect } from "react";

import { getLocation } from "../rest";

const useVisible = () => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {

        const check = async () => {
            const accepted = localStorage.getItem('cookie-consent-accepted');
            if (!accepted) {

                const result = await getLocation();
                if (result) {
                    const { continent_code } = result;
                    if (continent_code == 'EU') setVisible(true);
                }

            }
        }

        const timeout = setTimeout(() => check(), 1000);

        return () => clearTimeout(timeout);        
    }, []);


    return visible;
}

const CookieConsent = () => {

    const [closed, setClosed] = useState(false);
    const visible = useVisible();

    const handleClick = () => {
        localStorage.setItem('cookie-consent-accepted', true);
        setClosed(true);
    }

    if (!visible) return;
    if (closed) return;

    return (
        <div className="fixed bottom-2 left-1/2 px-4 py-4 bg-slate-700/90 rounded-md -translate-x-1/2 flex flex-row items-center space-x-2">
            <div className="text-sm text-white">We use cookies to sprinkle some magic on your browsing experience.</div>
            <button
                className="group px-2 py-1 border border-gray-400 rounded transition-all duration-150 hover:!border-white flex flex-row items-center justify-center"
                onClick={() => handleClick()}>
                <span className="text-sm text-white group-active:translate-y-px">Ok</span>
            </button>
        </div>
    )

}

export default CookieConsent;