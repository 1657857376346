import Film from "../common/Film";

const LegalNotices = () => {

    document.title = 'Demo Builder - Legal Notices';
    document.getElementsByTagName('meta')['description'].content = 'A list of current terms and policies relating to using Demo Builder';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, legal notices';


    return (
        <>
            <Film title="Subscription Agreement" description="Last Updated April 22, 2024." />

            <div className="page flex flex-col space-y-4">

                <p>This Subscription Agreement ("Agreement") is entered into by and between Tango Delta ("Provider"), and the Subscriber ("Subscriber"), an individual or entity utilizing the services provided by the Provider.</p>

                <h1 className="font-semibold text-lg">1. Subscription Services:</h1>

                <p>Provider hereby grants Subscriber a non-exclusive, non-transferable right to access and use its Software as a Service (SaaS) platform, hereinafter referred to as the "Service," solely for Subscriber's internal business operations during the term of this Agreement, subject to the terms and conditions herein.</p>

                <h1 className="font-semibold text-lg">2. Subscription Term:</h1>

                <p>The initial subscription term shall commence upon the date of acceptance of this Agreement by Subscriber and shall continue for the period specified in the subscription plan chosen by Subscriber. Thereafter, the subscription shall automatically renew for successive periods unless either Party provides written notice of termination.</p>

                <h1 className="font-semibold text-lg">3. Subscription Fees:</h1>

                <p>Subscriber agrees to pay Provider the applicable subscription fees as set forth in the selected subscription plan. Fees shall be invoiced and payable in advance of each subscription term. Provider reserves the right to modify subscription fees upon written notice to Subscriber.</p>

                <h1 className="font-semibold text-lg">4. Use of Service:</h1>

                <p>Subscriber shall use the Service in compliance with all applicable laws, regulations, and this Agreement. Subscriber shall not (a) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make the Service available to any third party; (b) modify, adapt, or hack the Service; or (c) reverse engineer, decompile, or disassemble the Service.</p>

                <h1 className="font-semibold text-lg">5. Intellectual Property:</h1>

                <p>Provider retains all right, title, and interest in and to the Service, including all intellectual property rights. This Agreement does not convey to Subscriber any ownership interest in the Service or any intellectual property rights therein.</p>

                <h1 className="font-semibold text-lg">6. Confidentiality:</h1>

                <p>Both Parties agree to maintain the confidentiality of all non-public information disclosed by the other Party during the term of this Agreement and for a period of [insert duration] thereafter. This obligation shall not apply to information that (a) is or becomes publicly known through no fault of the receiving Party, (b) was rightfully known or becomes known to the receiving Party without confidential or proprietary restriction from a source other than the disclosing Party, or (c) is independently developed by the receiving Party without reference to the disclosing Party's confidential information.</p>

                <h1 className="font-semibold text-lg">7. Termination:</h1>

                <p>Either Party may terminate this Agreement immediately upon written notice if the other Party breaches any material term of this Agreement and fails to cure such breach within 30 days after receipt of written notice thereof.</p>

                <h1 className="font-semibold text-lg">8. Limitation of Liability:</h1>

                <p>IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE.</p>

                <h1 className="font-semibold text-lg">9. Governing Law:</h1>

                <p>This Agreement shall be governed by and construed in accordance with the laws of Romania, excluding its conflicts of law principles.</p>

                <h1 className="font-semibold text-lg">10. Entire Agreement:</h1>

                <p>This Agreement, including all exhibits and addenda hereto and all order forms and other documents incorporated by reference, constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether written or oral, relating to such subject matter.</p>


            </div>

        </>
    )

}


export default LegalNotices;