
import classNames from "classnames";


const CTA = ({ className, title, description, caption, svg, url }) => {

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;


    return (

        <div className="my-12 flex flex-col items-center">

            {title && <h1 className="font-semibold text-2xl text-center">{title}</h1>}
            {description && <p className="mt-2 text-center">{description}</p>}

            <a
                className={classNames('group mt-6 px-6 py-2 bg-teal-500 rounded flex flex-row items-center space-x-4', className)}
                href={url}>
                <span className="font-semibold text-sm text-white uppercase">{caption}</span>

                {icon &&
                    <img
                        className="h-10 filter-white group-active:translate-x-px"
                        src={icon} />
                }
            </a>


        </div>



    )

}


export default CTA;