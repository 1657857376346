
import classNames from "classnames";

const Film = ({ title, description, svg, children }) => {


    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;

    const content = title || description || icon;

    return (

        <div className={classNames('w-full min-h-[10rem] py-12 bg-slate-800 text-white bg-repeat-x bg-film flex flex-col items-center', !content && '!pb-0')}>

            {content &&
                <div className="page">

                    {title && <h1 className="font-semibold text-2xl">{title}</h1>}
                    {description && <p className="mt-2">{description}</p>}

                    {icon &&
                        <img
                            className="hidden absolute right-0 -top-[2rem] z-50 w-auto h-[calc(100%+4rem)] lg:block"
                            src={icon} />
                    }

                    {children}

                </div>
            }

            {!content && children}


        </div>


    )

}


export default Film;