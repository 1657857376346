import { useState } from "react";

import classNames from "classnames";

const Player = ({ url, close }) => {

    const [transition, setTransition] = useState('in');

    const handleClose = () => {

        setTransition('out');

        setTimeout(() => {
            document.body.style.overflow = 'visible';
            close();
        }, 300);
    }


    document.body.style.overflow = 'hidden';

    return (
        <div className={classNames('fixed top-0 left-0 right-0 bottom-0 z-[999999] bg-[#f7f7f7] shadow-lg', transition == 'in' && 'animate-slide-left-in', transition == 'out' && 'animate-slide-right-out')}>

            <div className="absolute left-0 top-0 bottom-0 w-12 bg-slate-950 -translate-x-full"></div>

            <iframe
                className="absolute left-0 top-0 right-0 bottom-0 w-full h-full"
                src={url}>
            </iframe>

            <button
                className="group absolute right-0 bottom-24 px-4 py-2 bg-red-600 rounded-l-md shadow flex flex-row items-center justify-center"
                onClick={() => handleClose()}>
                <span className="font-semibold text-sm text-white uppercase group-active:translate-y-px">Close</span>
            </button>

        </div>
    )

}

export default Player;