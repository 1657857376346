

const Clients = () => {

    const images = require.context('../assets/clients/', false);
    const get = (file) => images(`./${file}.png`);

    const list = ['allianz', 'fujitsu', 'ibm', 'microsoft', 'american_o', 'american_t', 'apm', 'intel', 'bdc', 'husky', 'cornell', 'dhl', 'FIU', 'goodyear', 'hitachi', 'hp', 'cardif', 'nokiasiemens', 'siemens', 'wisconsin', 'wrberkley']
    const listClients = list.map(item =>
        <img
            className="w-3/4 opacity-70 grayscale transition-all duration-200 hover:!grayscale-0 hover:opacity-!100"
            key={item}
            src={get(item)} />

    )

    return (
        <>
            <h3 className="mt-24 font-semibold text-lg text-center">Trusted by users and teams of all sizes</h3>
            <ul className="my-8 grid grid-cols-7">
                {listClients}
            </ul>
        </>
    )

}

export default Clients;