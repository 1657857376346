
import Film from "../common/Film";
import Faq from "../common/Faq";
import Clients from "../common/Clients";


import classNames from "classnames";


const Offer = ({ description, percent }) => {

    const today = new Date();
    const currentDay = today.getDate();

    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const month = lastDayOfMonth.toLocaleString('default', { month: 'long' });
    const day = currentDay <= 15 ? 15 : lastDayOfMonth.getDate();
    const year = lastDayOfMonth.getFullYear();

    return (
        <div className="mb-16 flex flex-row items-center space-x-2">

            <img
                className="h-10 drop-shadow"
                src={require("../assets/svg/discount.svg").default} />

            <h1 className="font-semibold text-sm uppercase">Get a {percent}% lifetime discount + <a className="underline underline-offset-4 decoration-slate-400 hover:!decoration-slate-700" href="https://www.videodetailer.com" target="_blank">Video Detailer</a> for free!</h1>
            {description && <p className="text-sm opacity-70">{description}</p>}

            <p className="hidden sm:block px-3 py-0.5 bg-slate-200 rounded-full text-sm italic text-gray-600">Offer ends {month} {day}, {year}</p>

        </div>
    )
}

const OldVersion = () => {

    return (
        <div className="px-4 py-4 bg-slate-100 rounded flex flex-row items-center space-x-8">

            <img
                className="h-16 drop-shadow"
                src={require("../assets/svg/star.svg").default} />

            <div>
                <h1 className="font-semibold">Still in love with the old version of <a className="underline underline-offset-4 decoration-slate-400 hover:!decoration-slate-700" href="https://legacy.demo-builder.com" target="_blank">Demo Builder for Windows</a>?</h1>
                <p>Subscribe to the Creator plan and it's all yours at no cost! Make the transition at your own pace.</p>

            </div>
        </div>
    )

}

const Info = ({ className, text }) => {

    return (
        <div className="hidden relative group cursor-pointer md:block">
            <svg
                className="w-5 h-5 opacity-70 pointer-events-none group-hover:!opacity-100"
                width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 51.6C42.2606 51.6 52.2 41.6606 52.2 29.4C52.2 17.1392 42.2606 7.19995 30 7.19995C17.7393 7.19995 7.8 17.1392 7.8 29.4C7.8 41.6606 17.7393 51.6 30 51.6Z" stroke="black" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.2342 19.5602C32.2214 20.1416 31.98 20.6946 31.5622 21.099C31.1441 21.5034 30.5834 21.7266 30.0019 21.7201C29.5781 21.706 29.1679 21.5674 28.8223 21.3216C28.4765 21.0759 28.211 20.7337 28.0586 20.338C27.906 19.9423 27.8734 19.5104 27.9648 19.0962C28.056 18.6821 28.2672 18.3041 28.572 18.0092C28.8768 17.7143 29.2615 17.5156 29.6784 17.4379C30.0953 17.3602 30.5261 17.407 30.9166 17.5724C31.307 17.7378 31.6402 18.0144 31.8744 18.3679C32.1089 18.7214 32.2339 19.1361 32.2342 19.5602ZM28.2982 39.6723V26.7603C28.2917 26.5335 28.331 26.3076 28.4141 26.0967C28.4971 25.8857 28.6219 25.6937 28.7813 25.5324C28.9404 25.3709 29.1307 25.2432 29.3405 25.1573C29.5502 25.0714 29.7754 25.0287 30.0019 25.032C30.2256 25.0318 30.4471 25.0769 30.6528 25.1648C30.8585 25.2524 31.0445 25.3808 31.1993 25.5423C31.3541 25.7038 31.4743 25.8949 31.5533 26.1041C31.6322 26.3134 31.6678 26.5366 31.6579 26.7603V39.6723C31.6579 39.8928 31.6145 40.1112 31.5302 40.315C31.4458 40.5188 31.3219 40.704 31.1659 40.86C31.0099 41.016 30.8249 41.1397 30.6211 41.2241C30.4171 41.3086 30.1987 41.352 29.9782 41.352C29.7576 41.352 29.539 41.3086 29.3352 41.2241C29.1314 41.1397 28.9462 41.016 28.7902 40.86C28.6342 40.704 28.5103 40.5188 28.4261 40.315C28.3416 40.1112 28.2982 39.8928 28.2982 39.6723Z" fill="black" />
            </svg>

            <div className={classNames('absolute top-1/2 right-0 z-[9999] px-2 py-1 bg-yellow-400 text-sm text-nowrap opacity-0 rounded pointer-events-none transition-opacity delay-74 duration-200 shadow translate-x-[calc(100%+0.5rem)] -translate-y-1/2 group-hover:!opacity-100', className)}>
                {text}
            </div>

        </div>
    )

}

const Row = ({ className, caption, info, items }) => {

    const check = require("../assets/svg/check.svg").default;

    const listItems = items.map((item, i) =>
        <div
            className={classNames('p-2 text-sm md:text-base flex flex-row items-center', className)}
            key={i}>
            {item === undefined &&
                < img
                    className="h-4 filter-green"
                    src={check} />
            }

            {item}

        </div>)

    return (
        <>
            <div className="p-2 flex flex-row items-center">
                <div className="text-xs md:text-base text-gray-700 flex-1">{caption}</div>
                {info && <Info text={info} />}
            </div>

            {listItems}
        </>
    )


}

const Plans = ({ plans, offer }) => {
    // console.log('plans: ', plans);

    const k = (v) => {
        if (v < 1000) return v;

        const v1 = Math.floor(v / 1000);
        const v2 = Math.floor((v % 1000) / 100)

        return v2 > 0 ? `${v1}.${v2}k` : `${v1}k`;
    }


    const items1 = plans.map(({ name }) => name);

    const items2_offer = offer ? plans.map(({ price }) => price && `${Math.floor(price / (1 - offer.percent / 100))}US/mo`) : null;
    const items2 = plans.map(({ price }) => price && `${price}US/mo`);

    const items3 = plans.map(({ metadata }) => metadata.export && `${metadata.export} mp4`);
    const items4 = plans.map(({ metadata }) => metadata.openai && `${k(Number(metadata.openai))} chars`);
    const items5 = plans.map(({ metadata }) => metadata.translate && `${k(Number(metadata.translate))} chars`);
    const items6 = plans.map(({ metadata }) => metadata.tts && `${k(Number(metadata.tts))} chars`);

    const items7 = plans.map(({ metadata }) => metadata.views ? metadata.views : 'unlimited');

    const checked = Array(plans.length).fill(undefined);

    return (
        <div className="mt-12 mb-24 divide-y divide-slate-100 grid grid-cols-4">

            <Row key="name" className="font-mono font-semibold text-indigo-500 uppercase" caption="" items={items1}></Row>

            {items2_offer &&
                <Row key="price_offer" className="font-serif !font-normal !text-sm !text-gray-500 line-through decoration-red-600" caption="" items={items2_offer}></Row>
            }
            <Row key="price" className="font-serif font-semibold" caption="" items={items2}></Row>


            <Row key="mp4" caption="Export" info="Monthly limits apply, excluding the Free plan." items={items3}></Row>
            <Row key="openai" caption="Rewrite" info="Monthly limits apply, excluding the Free plan." items={items4}></Row>
            <Row key="translate" caption="Translate" info="Monthly limits apply, excluding the Free plan." items={items5}></Row>
            <Row key="tts" caption="Text-To-Audio" info="Monthly limits apply, excluding the Free plan." items={items6}></Row>
            <Row key="views" caption="Views" items={items7}></Row>

            <Row key="record" caption="Capture workflow" items={checked}></Row>
            <Row key="share" caption="Share via public links" items={checked}></Row>
            <Row key="blur" caption="Blur sensitive information" items={checked}></Row>

            <Row key="interactivity" caption="Add interactive actions" items={checked}></Row>


            <Row key="transitions" caption="Motion and Transitions" items={checked}></Row>

            <Row key="watermark" caption="No watermark" items={checked}></Row>
            <Row key="support" caption="24x7 email support" items={checked}></Row>




        </div>
    )
}

const Pricing = ({ plans }) => {

    document.title = 'Demo Builder - Pricing';
    document.getElementsByTagName('meta')['description'].content = 'Take a look at our available plans. Unsubscribe at any time';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, subscribe, plans';



    const x = {
        // description: 'Available to existing Demo Builder customers.',
        percent: 30,
    }

    const today = new Date();
    const currentDay = today.getDate();
    // const offer = (currentDay <= 3 || (currentDay >= 15 && currentDay < 15 + 3)) ? null : x;

    const offer = null;


    const list = plans.filter(({ name }) => name != 'Business');

    return (
        <>
            <Film title="Pricing" description="Opt for a Creator subscription while actively working on videos, then switch to Standby." svg="pricing.svg" />

            <div className="page">

                {offer &&
                    <Offer {...offer} />
                }


                {list.length > 0 && <Plans plans={list} offer={offer} />}

                <OldVersion />

            </div>

            <div className="w-full bg-slate-50 flex flex-col items-center">
                <div className="page !mb-12">

                    <Faq />

                    <Clients />

                </div>
            </div>

        </>
    )

}


export default Pricing;