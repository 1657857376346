
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import Film from "../common/Film";

import Clients from "../common/Clients";

import CTA from "../common/CTA";

import classNames from "classnames";


// const rand1 = Math.floor(Math.random() * 3) + 1;
const rand1 = 2;
const rand2 = Math.floor(Math.random() * 4) + 1;

const Video = () => {

    const videos = require.context('../assets/video/', false);

    const ref = useRef(null);


    const take = videos(`./take${rand1}.mp4`);
    const poster = require(`../assets/img/thumb${rand2}.png`);


    const [posterLoaded, setPosterLoaded] = useState(false);
    const [posterClicked, setPosterClicked] = useState(false);

    const [status, setStatus] = useState(null);

    const statuses = {
        'ready': { caption: 'Play intro', action: () => { ref.current.play(); } },
        'playing': { className: 'opacity-0 group-hover:!opacity-100', caption: 'Pause', action: () => { ref.current.pause(); } },
        'paused': { caption: 'Resume', action: () => { ref.current.play(); } },
        'ended': { caption: 'Replay', action: () => { ref.current.pause(); ref.current.currentTime = 0; ref.current.play(); } },

    }

    const toggle = () => {
        if (!status) return;
        statuses[status].action();
        setPosterClicked(true);
    }


    return (

        <div className="w-full sm:w-auto flex flex-row items-end justify-center">
            <div className="group relative">

                <button
                    className={classNames('hidden absolute -left-[2rem] bottom-2 z-50 px-2 py-1 transition-all duration-500 rounded', status && 'animate-fade !flex flex-row items-center justify-center', statuses[status]?.className)}
                    onClick={() => toggle()}><span className="text-xs uppercase transition-all duration-500 drop-shadow-md group-hover:!text-teal-400">{statuses[status]?.caption}</span></button>

                <video
                    ref={ref}
                    className={classNames('opacity-0', posterClicked && 'animate-fade-in cursor-pointer')}
                    width="256"
                    height="256"
                    preload="auto"
                    onLoadedData={() => setStatus('ready')}
                    onPlay={() => setStatus('playing')}
                    onPause={() => setStatus('paused')}
                    onEnded={() => setStatus('ended')}
                    onClick={() => toggle()}>
                    <source src={take} type="video/mp4"></source>
                </video>

                <div
                    className={classNames('absolute right-0 bottom-0 opacity-0', status && 'cursor-pointer', posterLoaded && !posterClicked && 'animate-fade-in', posterLoaded && posterClicked && 'animate-fade-out')}
                    onClick={() => toggle()} >
                    <img
                        width="256"
                        height="256"
                        src={poster}
                        onLoad={() => setPosterLoaded(true)} />
                </div>


            </div>
        </div>

    )

}



const Sentence = () => {

    return (
        <div className="font-semibold text-xl lg:text-2xl text-center drop-shadow-md">Create dynamic software presentations, engaging tutorials,<br /> and comprehensive how-to guides.</div>
    )

}

const Highlight = () => {

    const images = require.context('../assets/svg/', false);

    const list = [
        {
            name: 'Auto-Translate',
            description: 'Craft your demo in your native language, and broaden your audience by seamlessly translating it into over 30 languages for greater accessibility.',
            tip: '30+ languages',
            svg: 'feat-translate.svg'
        },
        {
            name: 'Cutting-Edge Text-To-Audio',
            description: 'Convert text to audio using our AI voices, and we guarantee you\'ll believe it was recorded in a professional studio by a voice-over artist.',
            tip: '36+ voices',
            svg: 'feat-text-to-audio.svg'
        },
        {
            name: 'Share and Export',
            description: 'Share your video, folder, or entire workspace in a snap, or keep a copy for yourself by exporting it to MP4 format.',
            tip: 'Link or MP4',
            svg: 'feat-share.svg',
        },
    ]

    const listHighlights = list.map(({ name, description, tip, svg }) =>
        <li
            className="p-2 flex flex-col items-center space-y-2"
            key={name}>

            <div className="relative drop-shadow">
                <img
                    className="w-12 h-12 filter-teal"
                    src={images(`./${svg}`)} />
                {tip && <span className="absolute right-0 top-0 px-2 py-1 bg-slate-800 text-xs text-nowrap text-white rounded-md translate-x-full -translate-y-full">{tip}</span>}
            </div>

            <h1 className="font-semibold text-lg capitalize">{name}</h1>
            <p className="text-sm text-center">{description}</p>

        </li>)

    return (
        <ul className="my-32 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {listHighlights}
        </ul>
    )

}


const useRotate3D = (ref) => {

    const mapNumberRange = (n, a, b, c, d) => {
        return ((n - a) * (d - c)) / (b - a) + c;
    };

    const calculateTransformValues = (pointerX, pointerY, cardRect) => {
        const halfWidth = cardRect.width / 2;
        const halfHeight = cardRect.height / 2;
        const cardCenterX = cardRect.left + halfWidth;
        const cardCenterY = cardRect.top + halfHeight;
        const deltaX = pointerX - cardCenterX;
        const deltaY = pointerY - cardCenterY;
        const distanceToCenter = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        const maxDistance = Math.max(halfWidth, halfHeight);
        const degree = mapNumberRange(distanceToCenter, 0, maxDistance, 0, 10);
        const rx = mapNumberRange(deltaY, 0, halfWidth, 0, 1);
        const ry = mapNumberRange(deltaX, 0, halfHeight, 0, 1);
        return { rx, ry, degree };
    };

    const applyTransform = (rx, ry, degree) => {
        const cardTransform = `perspective(1800px) rotate3d(${-rx}, ${ry}, 0, ${degree}deg)`;
        ref.current.style.transform = cardTransform;
    };

    const handleMouseMove = ({ clientX, clientY }) => {
        const card = ref.current;
        const cardRect = card.getBoundingClientRect();

        const {
            rx,
            ry,
            degree,
        } = calculateTransformValues(clientX, clientY, cardRect);

        applyTransform(rx, ry, degree);
    };

    const handleMouseLeave = () => {
        ref.current.style.transform = null;
    };

    useEffect(() => {
        const card = ref.current;
        if (!card) return;

        card.addEventListener('mousemove', handleMouseMove);
        card.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            card.removeEventListener('mousemove', handleMouseMove);
            card.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [ref]);
};

const Tools = () => {

    const ref = useRef(null);

    useRotate3D(ref);

    const images = require.context('../assets/svg/', false);

    const list = [
        {
            name: 'Auto-Translate',
            svg: 'feat-translate.svg'
        },
        {
            name: 'Text-To-Audio',
            svg: 'feat-text-to-audio.svg'
        },
        {
            name: 'Capture your flow',
            svg: 'feat-record.svg'
        },
        {
            name: 'Import images',
            svg: 'feat-import.svg'
        },
        {
            name: 'Quickly share',
            svg: 'feat-share.svg'
        },
        {
            name: 'Export your video',
            svg: 'feat-export.svg'
        },
        {
            name: 'Add Cursors',
            svg: 'feat-cursors.svg'
        },
        {
            name: 'Touch effect',
            svg: 'feat-touch.svg'
        },
        {
            name: 'Annotate',
            svg: 'feat-annotate.svg'
        },
        {
            name: 'Live zoom',
            svg: 'feat-zoom.svg'
        },
        {
            name: 'Hide Sensitive Information',
            svg: 'feat-blur.svg'
        },
        {
            name: 'Themes and mockups',
            svg: 'feat-templates.svg'
        },
        {
            name: 'Intro and outro scenes',
            svg: 'feat-intro.svg'
        },
        {
            name: 'Focus',
            svg: 'feat-focus.svg'
        },
        {
            name: 'Buttons',
            svg: 'feat-button.svg'
        },
        {
            name: 'Type animation',
            svg: 'feat-type.svg'
        },

    ]

    const listTools = list.map(({ name, svg }) =>
        <li
            className="p-4 flex flex-col items-center space-y-4"
            key={name}>

            <img
                className="w-8 h-8 filter-orange opacity-70 drop-shadow-sm"
                src={images(`./${svg}`)} />

            <p className="text-sm text-white text-center capitalize">{name}</p>

        </li>)

    return (
        <div
            ref={ref}
            className="relative px-2 py-16 bg-slate-800 border border-slate-900 duration-500 rounded-xl shadow-2xl flex flex-col items-center">

            <div className="absolute left-0 top-0 right-0 bottom-0 z-50 bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,.3)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat transition-[background-position_0s_ease] hover:bg-[position:200%_0,0_0] hover:duration-[2500ms]">
            </div>

            <h1 className="font-semibold text-2xl text-white text-center">Edit and fine-tune with <span className="text-orange-600">powerful tools</span></h1>
            <p className="mt-4 text-sm text-white text-center">Craft fully interactive presentations and tutorials using an impressive array of tools and animations to captivate your audience.</p>

            <ul className="mt-16 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                {listTools}
            </ul>

        </div>
    )

}


const Index = () => {

    return (
        <>
            <Film>

                <div className="w-full h-[16rem] mt-2 px-4 lg:!px-0 lg:w-[1024px] flex flex-row">
                    <div className="mt-6 flex-1 hidden sm:block">
                        <h1 className="font-semibold text-2xl capitalize">Create video demonstrations</h1>
                        <p>for customer support, employee training and product presentations</p>

                        <a
                            className="group mt-8 px-6 py-2 border-2 border-teal-500 rounded transition-colors hover:bg-teal-500 inline-flex flex-row items-center space-x-6"
                            href="https://run.demo-builder.com">
                            <span className="font-semibold text-sm text-white uppercase">Start now</span>
                            <img
                                className="h-10 filter-white group-active:translate-x-px"
                                src={require("../assets/svg/cta-run.svg").default} />
                        </a>

                    </div>

                    <Video />
                </div>

            </Film>

            <div className="page">

                <Sentence />

                <Highlight />

                <Tools />

                <CTA title="Ready to create your first demo?" description="Efficiently create practical training material with AI." caption="Start now" svg="cta-run.svg" url="https://run.demo-builder.com" />

                <Clients />

            </div>
        </>

    )

}


export default Index;