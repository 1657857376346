
import Film from "../common/Film";
import CTA from "../common/CTA";

const Feature = ({ name, description, svg }) => {

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;


    return (
        <div className="group px-0 py-4 transition-all cursor-default hover:bg-slate-50 flex flex-row items-start">

            <div className="hidden mr-4 h-full p-4 bg-slate-400 group-hover:!bg-teal-500 rounded-full md:flex flex-row items-center justify-center">
                <img
                    className="w-4 h-4 filter-white group-hover:scale-125"
                    src={icon} />
            </div>

            <div className="flex-1 flex flex-col">
                <h1 className="font-semibold text-teal-500">{name}</h1>
                <p className="text-sm opacity-70 group-hover:!opacity-100">{description}</p>
            </div>

            <button className="px-2 opacity-40 hover:!opacity-100 flex flex-row items-center justify-center">
                <img
                    className="h-5 filter-black"
                    src={require("../assets/svg/view.svg").default} />
            </button>

        </div>
    )

}


const Features = () => {

    document.title = 'Demo Builder - Features';
    document.getElementsByTagName('meta')['description'].content = 'Create Video Demonstrations for customer support, employee training and product presentations. Learn more about Demo Builder features.';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, auto-translate, capture flow, screen recorder, text-to-speech, tutorials';


    const categories = [

        {

            name: 'Translate + Speak',
            features: [
                {
                    name: 'Auto-Translate in 30+ languages',
                    description: 'Create in your native language and share your demo in 30+ languages.',
                    svg: 'feat-translate.svg',
                },
                {
                    name: 'Cutting-Edge Text-To-Audio using 36+ voices',
                    description: 'Convert text to audio using our AI voices, and we guarantee you\'ll believe it was recorded in a professional studio by a voice-over artist.',
                    svg: 'feat-text-to-audio.svg',
                },


            ]

        },

        {

            name: 'Record or Import',
            features: [
                {
                    name: 'Capture your flow',
                    description: 'Record the steps you want to demonstrate in your demo using our recorder.',
                    svg: 'feat-record.svg',
                },
                {
                    name: 'Import images',
                    description: 'You have the option to effortlessly import your own screenshots or images.',
                    svg: 'feat-import.svg',
                },
                {
                    name: 'Precision Cropping',
                    description: 'Use crop to show only what you want to showcase in the demo.',
                    svg: 'feat-crop.svg',
                },
                {
                    name: 'Hide Sensitive Information',
                    description: 'Block out personal or confidential information that was captured during recording (e.g. email addresses, names, etc.).',
                    svg: 'feat-blur.svg',
                },

            ]

        },

        {

            name: 'Design it like a PRO',
            features: [
                {
                    name: 'Record and edit Cursors',
                    description: 'Add cursor animations to clearly explain to the user how to interact with your website or application.',
                    svg: 'feat-cursors.svg',
                },
                {
                    name: 'Touch effect',
                    description: 'Want to show steps in a mobile app? Then the touch effect is here to help you.',
                    svg: 'feat-touch.svg',
                },
                {
                    name: 'Texts and Arrows',
                    description: 'Add texts and arrows to show and tell what is important in a particular scene.',
                    svg: 'feat-annotate.svg',
                },
                {
                    name: 'Live Zoom Area',
                    description: 'The Zoom Area brings the background to life instead of just displaying a static image.',
                    svg: 'feat-zoom.svg',
                },
                {
                    name: 'Copy and paste scenes from one movie into another',
                    description: 'Mix and match scenes from one movie into another by simply copying and pasting.',
                    svg: 'feat-copy-paste.svg',
                },
                {
                    name: 'Rewrite - Powered by GPT',
                    description: 'You can rephrase what you wrote in a friendly, formal or technical style.',
                    svg: 'feat-rewrite.svg',
                },
                {
                    name: 'Table of contents',
                    description: 'Add Table of Contents and let the user easily navigate between scenes.',
                    svg: 'feat-toc.svg',
                },
                {
                    name: 'Themes and mockups',
                    description: 'Creating visual representations, select a specific theme, such as placing your app within a computer mockup.',
                    svg: 'feat-templates.svg',
                },
                {
                    name: 'Intro, outro and chapter scenes',
                    description: 'Add friendly entrance or exit scenes where you can provide information or instructions.',
                    svg: 'feat-intro.svg',
                },

            ]

        },

        {

            name: 'Engaging Interaction',
            features: [
                {
                    name: 'Focus',
                    description: 'Click or touch in a specific area to continue the movie.',
                    svg: 'feat-focus.svg',
                },
                {
                    name: 'Buttons',
                    description: 'After the user has seen the scene, it\'s up to them when to move on further, or maybe they want to see it again.',
                    svg: 'feat-button.svg',
                },
                {
                    name: 'Type',
                    description: 'Show the user that they need to type something or ask them to type that text for the movie to continue.',
                    svg: 'feat-type.svg',
                },


            ]

        },

        {

            name: 'Share + Export',
            features: [
                {
                    name: 'Quick and easy sharing',
                    description: 'Instantly share your video, folder, or entire workspace.',
                    svg: 'feat-share.svg',
                },
                {
                    name: 'Export your movie',
                    description: 'Export your video in MP4 format and download it to your computer.',
                    svg: 'feat-export.svg',
                },


            ]

        },

    ]

    const listKeypoints = categories.map(({ name }) =>
        <li
            className="mr-2 mb-2 px-2 py-1 bg-slate-100 rounded text-sm"
            key={name}>

            {name}

        </li>
    )

    const listFeatures = (features) => features.map(({ name, description, svg }) => <Feature key={name} name={name} description={description} svg={svg} />)

    const listCategories = categories.map(({ name, features }) =>
        <div key={name}>

            <h1 className="mb-2 font-semibold text-lg">{name}</h1>

            <div className="mb-4 divide-y divide-slate-100">{listFeatures(features)}</div>

        </div>
    )


    return (
        <>
            <Film title="Features" description="Demo Builder is ideal for creating professional and fully interactive presentations and tutorials." svg="features.svg" />

            <div className="page">

                <ul className="hidden mb-8 md:flex flex-row flex-wrap items-center">{listKeypoints}</ul>

                <div className="flex flex-col space-y-2">
                    {listCategories}
                </div>

                <CTA className="!bg-slate-800" title="Need more info?" description="You are welcome to browse through our tutorials to learn more" caption="View Tutorials" svg="cta-tutorials.svg" url="/tutorials" />
            </div>

        </>
    )

}


export default Features;