import { useState } from "react";

import Film from "../common/Film";

import Player from "../common/Player";



const Samples = ({ samples }) => {

    document.title = 'Demo Builder - Samples';
    document.getElementsByTagName('meta')['description'].content = 'Take a look at our samples made using Demo Builder and find out what it can do.';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, tutorials, demos, presentations, interactive';


    const [url, setUrl] = useState(null);
    const [lastSeen, setLastSeen] = useState(null);



    const listTags = (tags) => tags.map(tag => <span key={tag} className="px-3 py-1 bg-slate-100 text-xs text-gray-600 rounded">{tag}</span>)

    const sort = (a, b) => a.name.localeCompare(b.name);

    const listSamples = samples.sort(sort).map(({ id, name, description, tags, url }) =>
        <li
            className="relative p-4 bg-slate-50 border border-slate-100 rounded transition-all duration-75 hover:!border-slate-200 flex flex-col items-start"
            key={id}>
            
            {lastSeen == id &&
                <div className="absolute right-3 top-0">
                    <img
                        className="mt-1 h-4 filter-neutral"
                        src={require("../assets/svg/bookmark.svg").default} />
                </div>
            }

            <div className="absolute left-0 top-0 w-16 h-px bg-orange-200"></div>
            <div className="absolute left-0 top-0 h-16 w-px bg-orange-200"></div>

            <div className="absolute right-0 bottom-0 h-16 w-px bg-teal-200"></div>
            <div className="absolute right-0 bottom-0 w-16 h-px bg-teal-200"></div>


            <div className="flex-1 flex flex-col items-start">
                <h1 className="font-semibold capitalize">{name}</h1>
                <p className="text-sm">{description}</p>
            </div>

            <button
                className="group mt-12 px-4 py-2 bg-indigo-500 text-white rounded flex flex-row items-center space-x-2"
                onClick={() => { setLastSeen(id); setUrl(url); }}>

                <img
                    className="h-4 filter-white group-active:scale-110"
                    src={require("../assets/svg/play.svg").default} />

                <span className="text-xs">Play</span>
            </button>

            <div className="mt-4 mb-4 w-full h-px bg-slate-200"></div>

            <div className="flex flex-row flex-wrap items-center space-x-2">{listTags(tags)}</div>

        </li>
    )


    return (
        <>
            <Film title="Samples" description="Take a look at our tutorial videos made using Demo Builder and find out what it can do." svg="samples.svg" />

            <div className="page">

                <ul className="grid md:grid-cols-2 gap-4">
                    {listSamples}
                </ul>

            </div>

            {url && <Player url={url} close={() => setUrl(null)} />}
        </>

    )

}


export default Samples;