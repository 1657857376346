import { useState } from "react";

import Film from "../common/Film";

import Player from "../common/Player";

import classNames from "classnames";

const Tutorials = ({ tutorials }) => {
    // console.log('tutorials: ', tutorials);

    document.title = 'Demo Builder - Tutorials';
    document.getElementsByTagName('meta')['description'].content = 'Our video tutorials are here to help you get the most out of your Demo Builder experience.';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, tutorials, how-tos, demos';


    const images = require.context('../assets/svg/', false);

    const [url, setUrl] = useState(null);
    const [lastSeen, setLastSeen] = useState(null);


    const dict = {
        'root': {
            caption: '',
            svg: 'category-root.svg'
        },
        'basics': {
            caption: 'Basics',
        },
        'edit': {
            caption: 'Explore further',
        },
    }

    const sort = (a, b) => a.name.localeCompare(b.name);

    const listItems = (items) => items.sort(sort).map(({ id, name, description, url }) =>
        <div
            className="group relative px-2 py-0.5 cursor-pointer rounded-sm hover:bg-white flex flex-row items-center"
            key={id}
            onClick={() => { setLastSeen(id); setUrl(url); }}>

            {lastSeen == id &&
                <div className="absolute -left-3 sm:-left-5 top-0.5">
                    <img
                        className="mt-1 h-4 filter-neutral"
                        src={require("../assets/svg/bookmark.svg").default} />
                </div>
            }

            <img
                className="mr-2 h-4 filter-teal group-active:scale-110"
                src={require("../assets/svg/play.svg").default} />

            <h1 className="flex-1 font-semibold text-slate-900 capitalize">
                {name}
            </h1>

        </div>)


    const listTutorials = tutorials.map(({ name, items }, i) =>

        <div className="flex flex-col" key={name}>
            <div className="flex flex-row items-start">

                <div className="hidden sm:block w-[18rem]">

                    {dict[name].svg &&
                        <img
                            className="animate-fade-in w-6 h-6 filter-black"
                            src={images(`./${dict[name].svg}`)} />

                    }

                    <h1 className="font-bold text-sm text-slate-900 uppercase">
                        {dict[name].caption}
                    </h1>

                </div>

                <div className="flex flex-col space-y-2">
                    {listItems(items)}
                </div>


            </div>

            {i < tutorials.length - 1 &&
                <div className="my-6 h-1 bg-slate-800 rounded-full"></div>
            }

        </div>

    )


    return (
        <>
            <Film title="Tutorials" description="Get started creating your own demos by following these tutorials created with Demo Builder." svg="tutorials.svg" />

            <div className="w-full bg-[#f7f7f7] flex flex-col items-center">
                <div className="page !mb-12">

                    {listTutorials}

                </div>
            </div>

            {url && <Player url={url} close={() => setUrl(null)} />}
        </>

    )

}


export default Tutorials;