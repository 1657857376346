
import Film from "../common/Film";

import classNames from "classnames";

const Email = ({ name, email, children }) => {

    return (
        <div className="p-4 border border-slate-700 flex flex-col">
            <h1 className="font-semibold">{name}</h1>
            <a className="text-sm opacity-90 transition-opacity hover:!opacity-100" href={`mailto:${email}`}>{email}</a>

            {children}

        </div>
    )
}

const Address = ({ className, line1, line2, svg }) => {

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;


    return (
        <div className={classNames('mt-2 flex flex-row items-end', className)}>

            <div className="flex-1 flex flex-col space-y-1">
                <p className="font-light text-xs opacity-50">{line1}<br />{ line2}</p>
            </div>


            <img
                className="w-4 h-4 filter-white opacity-50"
                src={icon} />


        </div>
    )
}

const Person = ({ name, position, src }) => {


    return (
        <div className="relative h-[22rem] border-[1rem] border-white shadow-md flex flex-col">

            <img
                className="absolute w-auto max-h-[105%] left-1/2 bottom-0 origin-bottom -translate-x-1/2 -translate-y-12"
                src={src} />

            <div className="absolute left-0 right-0 bottom-0 pt-2 bg-white text-black flex flex-col items-center">

                <h1 className="font-semibold">{name}</h1>
                <p className="">{position}</p>

            </div>


        </div>
    )
}

const Number = ({ value, description }) => {

    return (
        <div className="p-4 flex flex-col space-y-2">
            <h1 className="font-mono text-2xl">{value}</h1>
            <div className="w-full h-px bg-teal-500"></div>
            <p className="font-bold">{description}</p>


        </div>
    )
}


const Contact = () => {

    document.title = 'Demo Builder - Contact';
    document.getElementsByTagName('meta')['description'].content = 'Contact us and find answers to your questions. We are always here to help.';
    document.getElementsByTagName('meta')['keywords'].content = 'demo builder, contact';

    const person1 = require("../assets/img/team1.png");
    const person2 = require("../assets/img/team2.png");
    const person3 = require("../assets/img/team3.png");



    return (
        <>
            <Film title="Contact" description="With 15 years of experience under our belt, Tango Delta excels in screen recording, tutorial development, and demo creation.">

                <p>Our guiding principle at Demo Builder is to prioritize simplicity.</p>

                <p className="mt-4">We have spent many years developing the product that could be installed on the Windows platform. The time has come for us to transition it online. We continue to offer support, and existing customers can access the customer area <a className="hover:underline" href="https://legacy.demo-builder.com" target="_blank">here</a>.</p>

                <div className="mt-12 grid md:grid-cols-3 gap-4">
                    <Email name="General Inquiries" email="info@demo-builder.com">
                        <Address key="eu" className="mt-8" line1="Rosetti #3" line2="Braila, 810023, Romania, Europe" svg="flag-eu.svg" />
                        <Address key="canada" line1="135 Wynford Dr #805" line2="Toronto, M3C 0J4, Canada, North America" svg="flag-canada.svg" />
                    </Email>
                    <Email name="Sales" email="sales@demo-builder.com" />
                    <Email name="Customer Support" email="support@demo-builder.com" />
                </div>

            </Film>
            <div className="w-full bg-slate-800 text-white flex flex-col items-center">

                <div className="page">

                    <h1 className="font-semibold text-xl text-center uppercase">Meet our team</h1>

                    <div className="mt-32 grid md:grid-cols-3 gap-4">

                        <Person name="Olga S." position="Chief Ninja" src={person1} />
                        <Person name="Dan T." position="Happiness Hero" src={person2} />
                        <Person name="Dean S." position="Full Stack Magician" src={person3} />

                    </div>

                    <p className="mt-12 font-semibold text-xl text-center">We believe that creating tutorials and demos should be effortless.</p>

                    <div className="mt-32 grid md:grid-cols-3 gap-4">

                        <Number value="200k+" description="Videos created with DemoBuilder" />
                        <Number value="95%" description="Time saved on video creation" />
                        <Number value="15k" description="Companies served worldwide" />

                    </div>



                </div>
            </div>
        </>
    )

}


export default Contact;