
import classNames from "classnames";

const Menu = () => {

    const pathname = window.location.pathname;

    const pages1 = [{ name: 'Tutorials', href: '/tutorials' }, { name: 'Where\'s the old version?', href: 'https://legacy.demo-builder.com', target: '_blank' }];
    const listPages1 = pages1.map(({ name, href, target }) =>
        <li key={href}>
            <a
                className={classNames('opacity-50 transition-opacity hover:!opacity-100', pathname == href && '!opacity-30')}
                href={href}
                target={target || '_self'}>
                {name}
            </a>
        </li>
    )


    const pages2 = [{ name: 'Home', href: '/' }, { name: 'Features', href: '/features' }, { name: 'Samples', href: '/samples' }, { name: 'Pricing', href: '/pricing' }, { name: 'Contact', href: '/contact' }];
    const listPages2 = pages2.map(({ name, href }) =>
        <li key={href}>
            <a
                className={classNames('font-semibold uppercase opacity-100 transition-opacity hover:text-teal-500', pathname == href && '!opacity-60')}
                href={href}>
                {name}
            </a>
        </li>
    )

    return (
        <div
            className="page !mt-0 !pt-1 flex flex-row items-center lg:!items-end"
            id="#home">

            <a
                className="lg:mb-3"
                href="/">
                <img
                    className="h-6 drop-shadow-sm"
                    src={require("../assets/svg/logo.svg").default} />
            </a>


            <ul
                className="fixed left-0 top-0 right-0 bottom-0 z-[9999] bg-black/90 text-base text-white transition-transform -translate-y-full target:!translate-y-0 flex flex-col items-center justify-center space-y-6"
                id="mobile-menu">

                {listPages2}

                <a
                    className="group flex flex-row items-center space-x-2"
                    href="https://run.demo-builder.com">
                    <span className="font-semibold text-base text-teal-500 uppercase">Start now</span>
                    <img
                        className="hidden h-10 lg:block group-active:translate-x-px"
                        src={require("../assets/svg/cta-run.svg").default} />
                </a>

                <a
                    className="group fixed top-2 right-4 flex flex-row items-center justify-center"
                    href="#home">
                    <img
                        className="h-8 filter-white group-active:scale-90"
                        src={require("../assets/svg/close.svg").default} />
                </a>


            </ul>

            <a
                className="group ml-auto lg:hidden"
                href="#mobile-menu">
                <img
                    className="h-10 group-active:translate-y-px"
                    src={require("../assets/svg/menu.svg").default} />
            </a>


            <div className="hidden ml-auto lg:flex flex-col items-end space-y-2">

                <ul className="py-1 text-xs flex flex-row items-center space-x-3">
                    {listPages1}
                </ul>

                <ul className="text-sm flex flex-row items-center space-x-3">

                    {listPages2}

                    <a
                        className="group flex flex-row items-center space-x-2"
                        href="https://run.demo-builder.com">
                        <span className="font-semibold text-sm text-teal-600 uppercase">Start now</span>
                        <img
                            className="h-10 group-active:translate-x-px"
                            src={require("../assets/svg/cta-run.svg").default} />
                    </a>
                </ul>
            </div>


        </div>)

}

export default Menu;